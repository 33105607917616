import React from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from '../../../hooks/useDebounce';
export default function Flow() {
  const LANGFLOW_BASE_URL = process.env.REACT_APP_IBL_LANGFLOW_APP_BASE_URL;
  const mentor = useSelector((state) => state.mentors.edit.mentor);

  useDebounce(
    () => {
      handleLangflowPostMessageAPI();
    },
    200,
    []
  );

  const handleLangflowPostMessageAPI = () => {
    const iframedSite = document.querySelector('#embed-mentor-langflow-app');
    const message = JSON.stringify({
      axd_token: localStorage.getItem('axd_token'),
      tenant: localStorage.getItem('current_tenant'),
    });
    iframedSite.contentWindow.postMessage(message, '*');
  };

  return (
    <iframe
      title={'Langlow APP'}
      id="embed-mentor-langflow-app"
      src={`${LANGFLOW_BASE_URL}/flow/${mentor?.unique_id}`}
      //src={`${LANGFLOW_BASE_URL}/login`}
    ></iframe>
  );
}
