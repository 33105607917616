import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
  isRouteErrorResponse,
  json,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  isJSON,
  Logout,
  SsoLogin,
  AppDataContext,
  GLOBAL_COLORS,
  getUserDataFromCookie,
  getUserName,
  api,
  sentryCreateBrowserRouter,
  useIblTranslation,
  Error503,
} from '@iblai/ibl-web-react-common';
import { createGlobalStyle } from 'styled-components';
import '@iblai/ibl-web-react-common/index.css';
import { Helmet } from 'react-helmet';
import IndexView from './views/IndexView';

import Error403 from './errors/pages/error403';
import Error404 from './errors/pages/error404';

import { Provider as ReduxProvider } from 'react-redux';
import './App.css';
import { store } from './lib/redux/store';
import LoggedOut from './modules/logged-out';
import { redirectToAuth } from './utils/auth';
import useAnonymousMode from './hooks/iframe/useAnonymousMode';
import ChromeExtSSOLogin from './modules/chrome-extension/chrome-ext-sso-login';
import ChromeExtSSOLoginComplete from './modules/chrome-extension/chrome-ext-sso-login-complete';
import { mentorIsIframe } from './utils/shared';
import CheckoutSuccess from './modules/checkout/success';
import CheckoutCancel from './modules/checkout/cancel';
import './i18n';
import Platform from './modules/platform';
import NoMentors from './modules/no-mentors';
import ShareChat from './modules/share/chat';
import Coupons from './modules/coupons';
import Checkout from './modules/checkout';

const ErrorPage = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Error404 />;
    } else if (error.status === 403) {
      return <Error403 />;
    } else if (error.status === 500) {
      return <Error503 />;
    }
  }
};

const initiateAppContextData = () => {
  return {
    ...localStorage,
    userData: isJSON(localStorage['userData'])
      ? JSON.parse(localStorage['userData'])
      : null,
    ibl_user_public_metadata: isJSON(
      localStorage.getItem('ibl_user_public_metadata')
    )
      ? JSON.parse(localStorage.getItem('ibl_user_public_metadata'))
      : null,
    userMetaData: {},
    userInContext: '',
  };
};

function App() {
  const [appData, setAppData] = useState(initiateAppContextData);
  const anomymous = useAnonymousMode();
  const { translate } = useIblTranslation();
  const userDataFromCookie = getUserDataFromCookie();

  useEffect(() => {
    if (appData.userMetaData?.language) {
      translate(appData.userMetaData.language);
    }
  }, [appData.userMetaData]);

  const authCheckFn = (shouldBeAdmin, shouldBeLoggedIn, appData) => {
    return async () => {
      if (anomymous || mentorIsIframe() || !shouldBeLoggedIn) {
        return [];
      }

      if (
        (shouldBeLoggedIn && (!appData?.axd_token || !appData?.dm_token)) ||
        !userDataFromCookie ||
        userDataFromCookie?.user_id !== appData.userData?.user_id
      ) {
        return redirectToAuth();
      }
      const getUserMetadataPromise = new Promise((_resolve, _reject) => {
        if (!Object.keys(appData.userMetaData).length) {
          api.ibledxusers.getUsersManageMetadata(
            { username: getUserName() },
            function (data) {
              _resolve({
                userMetaData: { ...data },
                userMetaDataLoaded: true,
              });
            },
            () => {
              _reject();
            }
          );
        } else {
          _resolve({
            userMetaData: { ...appData.userMetaData },
            userMetaDataLoaded: true,
          });
        }
      });
      const getTenantsPromise = new Promise((_resolve, _reject) => {
        const selectedTenant = localStorage.getItem('tenant');
        if (!localStorage.getItem('tenants')) {
          api.ibledxtenants.getUserTenants((tenants) => {
            api.iblutils.saveUserTenantsDataToLocalStorage(
              tenants,
              selectedTenant
            );
            _resolve({
              current_tenant: localStorage.getItem('current_tenant'),
              tenants: localStorage.getItem('tenants'),
            });
          });
        } else {
          api.iblutils.saveUserTenantsDataToLocalStorage(
            localStorage.getItem('tenants'),
            selectedTenant
          );
          _resolve({
            current_tenant: localStorage.getItem('current_tenant'),
            tenants: localStorage.getItem('tenants'),
          });
        }
      });
      try {
        const [_userMetaDataInfo, _tenantInfo] = await Promise.all([
          getUserMetadataPromise,
          getTenantsPromise,
        ]);
        const currentTenantData = JSON.parse(_tenantInfo.current_tenant);

        if (shouldBeAdmin && currentTenantData.is_admin) {
          throw new Response('Not Admin', { status: 403 });
        }
        setAppData({ ...appData, ..._userMetaDataInfo, ..._tenantInfo });
        return json({}, { status: 200 });
      } catch (error) {
        return json({}, { status: 500 });
      }
    };
  };
  const gsite_verif_content =
    process.env.REACT_APP_GOOGLE_SITE_VERIFICATION_CONTENT;

  return (
    <>
      <Helmet>
        {gsite_verif_content && (
          <meta name="google-site-verification" content={gsite_verif_content} />
        )}
      </Helmet>
      <GlobalStyles />
      <EmbedCustomStyles />
      <ReduxProvider store={store}>
        <AppDataContext.Provider
          value={{ appData: { ...appData }, setAppData }}
        >
          <RouterProvider
            router={sentryCreateBrowserRouter([
              {
                path: '/',
                errorElement: <ErrorPage />,
                children: [
                  {
                    path: '/',
                    element: <IndexView />,
                    loader: authCheckFn(false, true, appData),
                  },
                  {
                    path: '/no-mentors',
                    element: <NoMentors />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: '/index.html',
                    element: <IndexView />,
                    loader: authCheckFn(false, true, appData),
                  },

                  {
                    path: '/share/chat/:sessionId',
                    element: <ShareChat />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'sso-login',
                    element: <SsoLogin />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'platform/:platform/:mentor',
                    element: <Platform />,
                    loader: authCheckFn(false, true, appData),
                  },
                  {
                    path: 'platform/:platform/checkout/:sku',
                    element: <Checkout />,
                    loader: authCheckFn(false, true, appData),
                  },
                  {
                    path: 'platform/:platform/coupons/:code',
                    element: <Coupons />,
                    loader: authCheckFn(false, true, appData),
                  },
                  {
                    path: 'chrome-ext-sso-login',
                    element: <ChromeExtSSOLogin />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'chrome-ext-login-complete',
                    element: <ChromeExtSSOLoginComplete />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'payment/success',
                    element: <CheckoutSuccess appData={appData} />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'payment/cancel',
                    element: <CheckoutCancel />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'logged-out',
                    element: <LoggedOut />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'logout',
                    element: <Logout />,
                    loader: authCheckFn(false, false, appData),
                  },
                ],
              },
            ])}
          />
        </AppDataContext.Provider>
      </ReduxProvider>
    </>
  );
}

const GlobalStyles = createGlobalStyle`
  html {
    --grey: ${GLOBAL_COLORS.grey};
    --font: ${GLOBAL_COLORS.font};
    --accent: ${GLOBAL_COLORS.primary};
    --accenthover: ${GLOBAL_COLORS.secondary};
    --accent-2: ${GLOBAL_COLORS.tertiary};
    --gainsboro: ${GLOBAL_COLORS.grey_secondary};
    --white-smoke: ${GLOBAL_COLORS.white_smoke};
    --white: white;
    --black: black;
    --3: white;
  }
`;

const EmbedCustomStyles =
  new URLSearchParams(window.location.search).get('mode') === 'anonymous' &&
  mentorIsIframe()
    ? createGlobalStyle`
      
    .user-prompt-text{
        border-radius: 8px 8px 0 8px!important;
        min-height: 20px!important;
        padding: 6px 9px!important;
        font-size: 13px!important;
        line-height: 20px!important;
        box-shadow: 0 1px 2px rgba(0, 0, 0, .1)!important;
    }
    .user-prompt-text-block{
        max-width: 280px!important;
    }
    .learn-more-container{
        grid-row-gap: 3px!important;
    }
    .ai-response-container{
        border-radius: 15px 15px 15px 0px!important;
        padding-top: 7px!important;
        padding-bottom: 5px!important;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .1)!important;
    }
    .ai-gif-container{
        padding-top: 5px!important;
    }
    .learn-more-block{
        border-top: .3px solid #a7a7a736!important;
        margin-top: 5px!important;
        padding: 5px 20px 0!important;
    }
    .response-action-icon{
        width: 14px!important;
    }
    .response-action-label{
        font-size: 12px!important;
    }
    .logo-container{
        grid-column-gap: 10px!important;
    }
    .text-block-35.suggested-prompt-label{
        font-size: 12px!important;
    }
    .user-prompt-input-field.black-bg-dark-mode.whiten-border-in-dark-mode.whiten-in-dark-mode{
        font-size: 14px!important;
    }
    .logo-container img{
        width: 30px!important;
    }
    .default-suggestion-prompts-block, .ai-default-msg{
        font-size: 14px!important;
    }
    .ai-response-text-container{
        grid-row-gap: 5px!important;
    }
`
    : () => {
        return <></>;
      };

export default App;
